import React, { useRef, useCallback, memo } from 'react';
import moment from 'moment';
import Select from 'react-select';
import styled from 'styled-components';
import { FaSyringe } from 'react-icons/fa';
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  Tooltip,
} from 'reactstrap';
import { RupeeIcon } from '../../components/common/svgFiles';
import { connect } from 'react-redux';
import LabIcon from '../../assets/images/labTestTube.svg';
import CapsuleSvg from '../../assets/images/capsule.svg';
import { CapsuleLogo } from '../../components/common/svgFiles';

const Button = styled.button`
  background: #ffffff;
  border: 1px solid #714fff;
  border-radius: 6px;
  padding: 10px 16px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
`;
const ApprovedText = styled.span`
  color: ${(props) => props.color};
  font-size: 15px;
`;
const LogoContainer = styled.img`
  width: 25px;
  height: 25px;
`;

function AddItemsContainer({
  invoiceItems,
  renderInvoiceItems,
  addInvoiceItem,
  reimbursementDetail,
  oracleDentalCheck,
  oracleChecks,
  oracleCiScan,
  doctorVerifyByGoogleSearch,
  onDoctorVerifyCheck,
  policyDetail,
  finalApprovalStatus,
  availableProceduresData,
  brokerId,
  openLabModal,
  openMedicineModal,
  digitizationDetails,
  digitisedCart,
}) {
  const procedureArr = availableProceduresData?.result.filter(
    (data) => data.procedureId === reimbursementDetail.procedureId
  );
  const payableAmount = invoiceItems
    .filter((ele) => ele.itemId)
    .reduce((acc, ele) => acc + parseFloat(ele.paidAmount), 0);

  const renderApprovalStatus = (finalApprovalStatus) => {
    switch (finalApprovalStatus.toLowerCase()) {
      case 'pending':
        return (
          <ApprovedText className="h4" color="#ffc53d">
            PENDING
          </ApprovedText>
        );
      case 'qc-check':
        return (
          <ApprovedText className="h4" color="">
            QC-Check
          </ApprovedText>
        );
      case 'documents-verified':
        return (
          <ApprovedText className="h4" color="#fa8c16">
            DOCUMENTS VERIFIED
          </ApprovedText>
        );
      case 'partially-approved':
        return (
          <ApprovedText className="h4" color="#95de64">
            PARTIALLY APPROVED
          </ApprovedText>
        );
      case 'approved':
        return (
          <ApprovedText className="h4" color="#237804">
            APPROVED
          </ApprovedText>
        );
      case 'rejected':
        return (
          <ApprovedText className="h4" color="#cf1322">
            REJECTED
          </ApprovedText>
        );
      default:
        return (
          <ApprovedText className="h4" color="#ffc53d">
            PENDING
          </ApprovedText>
        );
    }
  };

  const renderHeading = () => (
    <div className="flex flex-row justify-evenly mb-3 heading-row">
      <div>
        <Label className="font-bold">Prescription Name</Label>
      </div>
      <div>
        <Label className="font-bold">Item</Label>
      </div>
      <div>
        <Label className="font-bold">Item Name</Label>
      </div>
      <div>
        <Label className="font-bold">Item Price</Label>
      </div>
      <div>
        <Label className="font-bold">Approved Amt.</Label>
      </div>
      <div>
        <Label className="font-bold">Remarks</Label>
      </div>
      {!brokerId && (
        <div>
          {/* This space can be used for the delete button if needed */}
        </div>
      )}
    </div>
  );

  return (
    <div className="relative ">
      {digitizationDetails && digitizationDetails.length === 0 ? (
        <div
          className="absolute"
          style={{
            height: '100%',
            width: '100%',
            cursor: 'not-allowed',
            left: '0',
            top: 0,
            zIndex: 12,
            borderRadius: '2px',
            background: '#77777777',
          }}
        />
      ) : (
        <></>
      )}
      <div className="bg-white rounded  mt-2 ">
        <div className="col-12" style={{ padding: '2px' }}>
          {reimbursementDetail.sentForQc &&
          procedureArr &&
          procedureArr[0]?.copayPercentage > 0 ? (
            <h4
              style={{
                fontWeight: 'bold',
                fontFamily: 'Montserrat',
                padding: '12px',
                background: '#FFF2EE',
                borderRadius: '12px',
                marginBottom: '12px',
              }}
            >
              Copay hasn’t been deducted yet, it will be deducted by system post
              QC Verification.<br></br>
              Please process accordingly.
            </h4>
          ) : (
            ''
          )}
          <div className="flex flex-row justify-end">
            <h2
              className="m-1"
              style={{ color: '#714FFF', fontSize: '17px', fontWeight: 'bold' }}
            >
              Invoice Items
            </h2>
            <h2
              className="flex ml-auto"
              style={{ color: '#3A3A4A', fontSize: '16px', fontWeight: 'bold' }}
            >
              Payable Amount - <RupeeIcon />
              {payableAmount || 0}
            </h2>
          </div>
          {!reimbursementDetail?.isScanPay ? (
            <div
              className="mt-2"
              style={{
                border: '1px solid #E3E3E3',
                borderRadius: '8px',
                backgroundColor: '',
                padding: '5px 10px',
                display: 'flex',
                flexDirection: 'column',
                height: '100%', // Ensure the container takes up the full height
              }}
            >
              <div style={{ flex: 1 }}>
                <div className="flex flex-row justify-between items-center mb-4">
                  <h2
                    style={{
                      color: '#714FFF',
                      fontSize: '17px',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <LogoContainer
                      src={LabIcon}
                      style={{ marginRight: '8px' }}
                    />
                    Add Lab Tests
                  </h2>
                </div>

                <div style={{ flex: 1 }}>
                  {/* List of items */}
                  <div>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {invoiceItems?.length ? (
                        invoiceItems.map((el, idx) => {
                          if (el.type === 'labs') {
                            return renderInvoiceItems(
                              el,
                              idx,
                              invoiceItems.findIndex(
                                (e) => e.type === 'labs'
                              ) === idx
                            );
                          } else {
                            return <></>;
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              </div>

              <Button
                className="mt-2"
                style={{
                  backgroundColor: '#714FFF',
                  color: '#fff',
                  marginTop: 'auto', // Pushes the button to the bottom of the container
                  alignSelf: 'flex-end', // Aligns the button to the right
                }}
                onClick={openLabModal}
              >
                + Add item
              </Button>
            </div>
          ) : (
            ''
          )}

          {reimbursementDetail?.isScanPay ? (
            <div
              className="mt-2"
              style={{
                border: '1px solid #E3E3E3',
                borderRadius: '8px',
                backgroundColor: '',
                padding: '5px 10px',
                display: 'flex',
                flexDirection: 'column',
                height: '100%', // Ensure the container takes up the full height
              }}
            >
              <div style={{ flex: 1 }}>
                <div className="flex flex-row justify-between items-center mb-4">
                  <h2
                    style={{
                      color: '#714FFF',
                      fontSize: '17px',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <LogoContainer
                      src={CapsuleSvg}
                      style={{ marginRight: '8px' }}
                    />
                    Digitised Cart Items
                  </h2>
                </div>

                <div style={{ flex: 1 }}>
                  {/* List of items */}
                  {/* {invoiceItems?.length > 0 ? renderHeading() : <></>} */}
                  <div>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {invoiceItems?.length ? (
                        invoiceItems.map((el, idx) => {
                          if (!el?.type) {
                            return renderInvoiceItems(
                              el,
                              idx,
                              invoiceItems.findIndex((e) => !e.type) === idx
                            );
                          } else {
                            return <></>;
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}

          <div
            className="mt-2"
            style={{
              border: '1px solid #E3E3E3',
              borderRadius: '8px',
              backgroundColor: '',
              padding: '5px 10px',
              display: 'flex',
              flexDirection: 'column',
              height: '100%', // Ensure the container takes up the full height
            }}
          >
            <div style={{ flex: 1 }}>
              <div className="flex flex-row justify-between items-center mb-4">
                <h2
                  style={{
                    color: '#714FFF',
                    fontSize: '17px',
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <LogoContainer
                    src={CapsuleSvg}
                    style={{ marginRight: '8px' }}
                  />
                  Add Medicine
                </h2>
              </div>

              <div style={{ flex: 1 }}>
                {/* List of items */}
                {/* {invoiceItems?.length > 0 ? renderHeading() : <></>} */}
                <div>
                  <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {invoiceItems?.length ? (
                      invoiceItems.map((el, idx) => {
                        if (el.type === 'medicine') {
                          return renderInvoiceItems(
                            el,
                            idx,
                            invoiceItems.findIndex(
                              (e) => e.type === 'medicine'
                            ) === idx
                          );
                        } else {
                          return <></>;
                        }
                      })
                    ) : (
                      <></>
                    )}
                  </ul>
                </div>
              </div>
            </div>

            <Button
              className="mt-2"
              style={{
                backgroundColor: '#714FFF',
                color: '#fff',
                marginTop: 'auto', // Pushes the button to the bottom of the container
                alignSelf: 'flex-end', // Aligns the button to the right
              }}
              onClick={openMedicineModal}
            >
              + Add item
            </Button>
          </div>
          {reimbursementDetail?.isScanPay ? (
            ''
          ) : (
            <div
              className="mt-2"
              style={{
                border: '1px solid #E3E3E3',
                borderRadius: '8px',
                backgroundColor: '',
                padding: '5px 10px',
                display: 'flex',
                flexDirection: 'column',
                height: '100%', // Ensure the container takes up the full height
              }}
            >
              <div style={{ flex: 1 }}>
                <div className="flex flex-row justify-between items-center mb-4">
                  <h2
                    style={{
                      color: '#714FFF',
                      fontSize: '17px',
                      fontWeight: 'bold',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FaSyringe
                      style={{ marginRight: '2px', fontSize: '1.5rem' }}
                    />
                    Add Others
                  </h2>
                </div>

                <div style={{ flex: 1 }}>
                  {/* List of items */}
                  {/* {invoiceItems?.length > 0 ? renderHeading() : <></>} */}
                  <div>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                      {invoiceItems?.length ? (
                        invoiceItems.map((el, idx) => {
                          if (!el?.type) {
                            return renderInvoiceItems(
                              el,
                              idx,
                              invoiceItems.findIndex((e) => !e.type) === idx
                            );
                          } else {
                            return <></>;
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <Button
                className="flex ml-auto mt-8"
                style={{ backgroundColor: '#714FFF', color: '#fff' }}
                onClick={addInvoiceItem}
              >
                + Add item
              </Button>
            </div>
          )}

          {/* <Form>
            {invoiceItems.length > 0 ? (
              invoiceItems.map(renderInvoiceItems)
            ) : (
              <span className="text-secondary text-center">
                No items added yet
              </span>
            )}
          </Form> */}
        </div>

        {
          brokerId && brokerId ? '' : ''
          // <div className="col-12 mt-3">
          //   <Button outline color="primary" onClick={addInvoiceItem}>
          //     + Add item
          //   </Button>
          //   {/* <h1 className='float-right'
          //   style={{ color: '#714FFF', fontSize: '17px', fontWeight: 'bold' }}
          // >

          //   Final Status : {renderApprovalStatus(finalApprovalStatus)}
          // </h1> */}
          // </div>
        }
      </div>

      {policyDetail &&
      policyDetail.vendorId === 8 &&
      !reimbursementDetail?.isScanPay ? (
        <div className="bg-white rounded  p-8 m-1 ">
          <div className="flex custom-checkbox">
            <div className="col-12 mt-2 mb-2">
              <Label check>
                <Input
                  type="checkbox"
                  className="checkbox"
                  checked={doctorVerifyByGoogleSearch}
                  onChange={onDoctorVerifyCheck()}
                />
                <p className="ml-2">
                  Verified doctor on the basis of Google search
                </p>
              </Label>
            </div>
          </div>
          <hr></hr>
          {reimbursementDetail.sponsorId === 731 ? (
            <div>
              <div className="flex custom-checkbox">
                <div className="col-12 mt-2 mb-2 ">
                  <Label check>
                    <Input
                      type="checkbox"
                      className="checkbox"
                      checked={oracleDentalCheck}
                      onChange={oracleChecks('oracleDentalCheck')}
                    />
                    <p className="ml-2">
                      Reimbursement Claim is not of these type
                      Dental/Vision/Ayurvedic/Day care treatment
                    </p>
                  </Label>
                </div>
              </div>
              <hr></hr>
              <div className="flex custom-checkbox">
                <div className="col-12 mt-2 mb-2">
                  <Label check>
                    <Input
                      type="checkbox"
                      className="checkbox"
                      checked={oracleCiScan}
                      onChange={oracleChecks('oracleCiScan')}
                    />
                    <p className="ml-2">
                      CT Scan, MRI, Biopsy, Bone Densitometry are not present in
                      the prescription
                    </p>
                  </Label>
                </div>
              </div>
              <hr></hr>
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  const userName = state.app.opsUser?.name;
  const searchString = state.app.searchString;
  const brokerId = state.app.opsUser?.brokerId;
  return { userName, searchString, brokerId };
};

export default connect(mapStateToProps)(AddItemsContainer);
